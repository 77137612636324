const sampleInitialValue = {
  // 헤더값
  headerType: 'normal', // 헤더 타입 (normal, logo)
  title: 'Main title', //  헤더 타이틀값
  isSticky: false, // 헤더가 항상 위에 존재하는지 유무
  btnText: '', // 텍스트 버튼 값 + 유무

  // headerType이 normal일 때만 동작
  isViewAdd: false, // 더하기 버튼 유무
  isViewAppClose: false, // 닫기 버튼 유무
  isViewInfo: false, // 정보 버튼 유무
  isViewMore: false, // 더보기 버튼 유무

  //  headerType이 logo일 때만 동작
  isViewSearch: false, // 검색 버튼 유무
  isViewAlarm: false, // 알람 버튼 유무
  hasAlarm: false, // 알람버튼이 true 일 때, 알람 유무
};

export default {
  // 젤리챌린지
  PGIFPMCJellyIntroductionIndex001: Object.assign({}, sampleInitialValue, {
    title: '젤리 챌린지 소개',
  }),
  PGIFPMPJellyMainApplyWalk001: Object.assign({}, sampleInitialValue, {
    title: '',
    btnText: '공유',
  }),
  PGIFPMPJellyMainApplyWakeup001: Object.assign({}, sampleInitialValue, {
    title: '',
    btnText: '공유',
  }),
  PGIFPMPJellyMainApplyApptech001: Object.assign({}, sampleInitialValue, {
    title: '',
    btnText: '공유',
  }),
  PGIFPMCJellyMainWakeupVerification001: Object.assign({}, sampleInitialValue, {
    title: '기상 챌린지',
  }),
};
